import React from 'react';

import { Spinner } from '@hub-fe/common/Animations/Animations';

const LoadingPage: React.FC<{ statusText?: string }> = props => {
    return (
        <div className="loading-page">
            <p className="row">{props.statusText}</p>
            <Spinner />
        </div>
    );
};

export default LoadingPage;
