import React, { useEffect } from 'react';

export const LoadingDots: React.FC = () => {
    return <span className="loading-dots"></span>;
};

export const InfiniteLoadingWheel: React.FC = () => {
    return (
        <div className="infinite-loading-wheel">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export const Spinner: React.FC = () => {
    return <div className="spinner" />;
};

interface IScrollAnimationClassNames {
    scrollClassName: string;
    animateClassName: string;
}

const scrollAnimationClassNames: IScrollAnimationClassNames[] = [
    { scrollClassName: 'fade-in-on-scroll', animateClassName: 'fade-in-element' },
    { scrollClassName: 'bounce-in-left-on-scroll', animateClassName: 'bounce-in-left-element' },
    { scrollClassName: 'bounce-in-right-on-scroll', animateClassName: 'bounce-in-right-element' },
    { scrollClassName: 'pulse-on-scroll', animateClassName: 'pulse-element' },
];

export function useAnimationScrollListener(): void {
    let windowHeight: number;
    let elements: NodeListOf<Element>;

    function init() {
        const queryScrollClassNames = scrollAnimationClassNames
            .map(c => {
                return `.${c.scrollClassName}`;
            })
            .join(',');

        elements = document.querySelectorAll(queryScrollClassNames);
        windowHeight = window.innerHeight;
    }

    function checkPosition() {
        elements.forEach(element => {
            const positionFromTop = element.getBoundingClientRect().top;

            if (positionFromTop - windowHeight <= 0) {
                scrollAnimationClassNames
                    .filter(c => element.classList.value.includes(c.scrollClassName))
                    .forEach(scrollClassNames => {
                        element.classList.add(scrollClassNames.animateClassName);
                        element.classList.remove(scrollClassNames.scrollClassName);
                    });
            }
        });
    }

    useEffect(() => {
        document.body.addEventListener('scroll', checkPosition);
        document.body.addEventListener('resize', init);

        init();

        return () => {
            document.body.removeEventListener('scroll', checkPosition);
            document.body.removeEventListener('resize', init);
        };
    });
}
