import React from 'react';

import { useAnimationScrollListener } from '@hub-fe/common/Animations/Animations';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import Landing from '@hub-fe/landing';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

import './index.scss';

const IndexPage: React.FC = () => {
    const description = `The simplest way to build and deploy SaaS applications. ${siteName} is a fully managed environment for rapid deployment of apps and services built with Daml.`;
    const keywords = `${siteName}, Daml, Daml platform, Daml deployment, deploy Daml app, Daml cloud, Daml managed environment, SaaS development, Open Source SaaS, Open Source`;
    const robots = 'index, follow';

    const metaProps = [
        { charSet: 'utf-8' },
        { name: 'site', content: 'damlhub' },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'robots', content: robots },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ];

    useAnimationScrollListener();

    return (
        <div className="roomy gatsby-index default">
            <DamlHubHelmet title={siteName} meta={metaProps} />
            <Landing />
        </div>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default IndexPage;
