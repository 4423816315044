import React from 'react';

import LoadingPage from '@hub-fe/common/LoadingPage';
import { login } from '@hub-fe/common/urls';

const Landing: React.FC = () => {
    React.useEffect(() => window.location.replace(`https://${login}`), []);
    return <LoadingPage />;
};

export default Landing;
